@font-face {
  font-family: 'MyFont';
  src: local('MyFont'), url(./Bypass3D.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.container {
  font-family: 'MyFont';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 100vw;
  color: white;
}

.songlink {
  width: 200px;
}

.bonus {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.link {
  text-decoration: none;
  color: inherit;
}

html,
body,
#root {
  margin: 0px;
  padding: 0px;
  height: 100vh;
  background-color: black;
}

.MuiPaper-root {
  color: rgb(255 255 255 / 87%);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #000 !important;
}

.inner-container {
  display: flex;
  flex-direction: row;
  width: 60%;
  padding: 70px;
  justify-content: space-evenly;
  align-items: center;
}

.gameboard td:hover {
  cursor: pointer;
  background-color: gainsboro;
}

.gameboard td {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 34px;
  font-weight: bold;
}

.gameboard tbody {
  display: flex;
  flex-direction: column;
}

.gameboard tr {
  display: flex;
}

.player1 {
  font-size: 120px !important;
  color: darkslateblue !important;
}

.player2 {
  font-size: 100px !important;
  color: lightseagreen !important;
}

.player-card {
  display: flex;
  align-items: space-around;
  width: 100%;
  padding: 14px;
  border-bottom: 1px solid;
}

.player-card:hover {
  cursor: pointer;
  border-bottom: 2px solid;
}

.active-p2 {
  border-bottom: 2px solid lightseagreen;
  box-shadow: 1px 1px 5px gray;
}

.gameboard-row .gameboard-cell.td:hover {
  cursor: pointer;
  background-color: gainsboro;
}

.gameboard-cell {
  width: 120px;
  height: 120px;
  border: 3px solid rgb(255, 255, 255);
}

.winner-modal {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.85);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0px;
  top: 0px;
  font-size: 30px;
  color: white;
}

.upper-left,
.upper-middle,
.upper-right {
  border-top: none;
}

.middle-left,
.upper-left,
.bottom-left {
  border-left: none;
}

.middle-right,
.bottom-right,
.upper-right {
  border-right: none;
}

.bottom-left,
.bottom-middle,
.bottom-right {
  border-bottom: none;
}
